import { IconSamespace } from "../FunctionView/icons";

import { useNavigate } from "react-router-dom";
import { useEffect, useState, useCallback, useRef } from "react";
import axios from "axios";
import { API_URL } from "../Server";
import Popover from "../Components/Popover";

import Select from "react-select";
import toast from "react-hot-toast";

const OptionLayout = (props) => {
  const { innerProps, innerRef } = props;
  return (
    <article
      title={props.data.description}
      ref={innerRef}
      {...innerProps}
      className=" text-[15px] hover:bg-gray-300 cursor-pointer truncate min-w-20 px-2 z-50 border-b"
    >
      <h4>{props.data.label}</h4>
      <div className="text-sm opacity-50">{props.data.description} </div>
    </article>
  );
};
const Verify = () => {
  const popRef = useRef();
  const [screenShots, setScreenShots] = useState([]);
  const [categories, setCategories] = useState([]);
  const getCategories = useCallback(async () => {
    const { data } = await axios.get(`${API_URL}/categories`);
    if (data) {
      setCategories([
        ...data.map((item) => {
          return {
            value: item._id,
            label: item.name,
            description: item.description,
          };
        }),
      ]);
    }
  }, []);

  const getScreenShots = async () => {
    const { data } = await axios.get(`${API_URL}/screenshots`);
    if (data) {
      setScreenShots(data);
    }
  };
  useEffect(() => {
    getScreenShots();
    getCategories();
  }, [getCategories]);
  const navigate = useNavigate();
  return (
    <div>
      <div className="px-24 my-8 flex items-center justify-between">
        {IconSamespace}
        <div className="flex items-center justify-center gap-x-4">
          <button
            onClick={() => {
              navigate("/");
            }}
            className="px-4 py-2 font-semibold text-sm bg-blue-500 text-white rounded-full shadow-sm"
          >
            Videos
          </button>
        </div>
      </div>

      <div className="flex flex-col">
        {screenShots.map((screenShot, i) => {
          return (
            <div className="flex items-center justify-between p-6 border-t border-b border-gray-200 mb-2">
              <div className="flex items-center gap-x-4">
                <img
                  className="w-[400px] h-[200px] object-cover rounded-sm border shadow-md"
                  src={`${API_URL}/screenshot/file/${screenShot.fileId}`}
                  alt={screenShot.fileId}
                />
                <div className="flex flex-col">
                  <div className="text-lg">{screenShot.category.label}</div>
                  <div className="text-sm opacity-50">
                    {screenShot.category.description}
                  </div>
                </div>
              </div>
              <Popover
                ref={popRef}
                trigger
                preferPlace="above"
                popup={
                  <div className="p-6">
                    <div>
                      <Select
                        components={{
                          Option: OptionLayout,
                        }}
                        value={screenShot.category}
                        options={categories}
                        onChange={(e) => {
                          popRef.current.hide();
                          const updateEvent = axios
                            .put(`${API_URL}/screenshot/${screenShot._id}`, {
                              category: {
                                ...e,
                              },
                            })
                            .then(() => {
                              const copyScreenShots = [...screenShots];
                              copyScreenShots[i].category = e;
                              setScreenShots([...copyScreenShots]);
                            });
                          toast.promise(updateEvent, {
                            loading: "Updating...",
                            success: "Updated",
                            error: "Error While Updating",
                          });
                        }}
                      />
                    </div>
                  </div>
                }
              >
                <button className="px-4 py-2 font-semibold text-sm bg-blue-500 text-white rounded-full shadow-sm mr-16">
                  Change
                </button>
              </Popover>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default Verify;
