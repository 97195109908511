import { API_URL } from "../../Server";

const VideoLoader = ({ videoId, error, setError, setVideoLoading }) => {
  return (
    <div className="h-[75vh] w-[90vw] cursor-pointer border flex items-center justify-center border-gray-200 rounded-md relative">
      {!error && (
        <video
          key={videoId}
          crossOrigin="anonymous"
          id="function-player"
          controls
          onError={() => {
            setError("Error loading video");
          }}
          onCanPlay={() => {
            setVideoLoading(false);
          }}
          onLoadStart={() => {
            setVideoLoading(true);
          }}
          className="w-full h-full"
          src={`${API_URL}/session/video?id=${videoId}`}
        />
      )}

      {error && <div className="text-red-500">{error}</div>}
    </div>
  );
};

export default VideoLoader;
