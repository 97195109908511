import axios from "axios";

import { useCallback, useEffect } from "react";
import { API_URL } from "../Server";

import { useNavigate } from "react-router-dom";
import Loader from "../Components/Loader";

const Home = () => {
  const navigate = useNavigate();
  const getFunctionIds = useCallback(async () => {
    const { data } = await axios.get(`${API_URL}/functions`);
    if (data) {
      navigate(`/${data[0]._id}/${data[0].name}`);
    }
  }, [navigate]);
  useEffect(() => {
    getFunctionIds();
  }, [getFunctionIds]);
  return (
    <div className="flex items-center justify-center h-screen w-screen flex-col">
      <Loader />
    </div>
  );
};

export default Home;
