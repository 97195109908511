import { useParams, useNavigate } from "react-router-dom";
import axios from "axios";
import { useCallback, useEffect, useRef, useState } from "react";
import VideoLoader from "../Components/VideoLoader";
import { BROWSERS } from "../utils/constants";
import { API_URL } from "../Server";
import { IconNoVideos, IconSamespace } from "./icons";

import Select from "react-select";

import { toast } from "react-hot-toast";

import Popover from "../Components/Popover";

import "react-dropdown/style.css";
import Loader from "../Components/Loader";
import { getCookie } from "../utils/common";

const OptionLayout = (props) => {
  const { innerProps, innerRef } = props;
  return (
    <article
      title={props.data.description}
      ref={innerRef}
      {...innerProps}
      className=" text-[15px] hover:bg-gray-300 cursor-pointer truncate min-w-20 px-2 z-50 border-b"
    >
      <h4>{props.data.label}</h4>
      <div className="text-sm opacity-50">{props.data.description} </div>
    </article>
  );
};

async function videoCapture(diementions, category, videoId, cb) {
  const tagImage = new Promise((res, rej) => {
    var canvas = document.createElement("canvas");
    var video = document.querySelector("#function-player");
    canvas.width = video.videoWidth;
    canvas.height = video.videoHeight;

    canvas
      .getContext("2d")
      .drawImage(
        video,
        diementions.x || 0,
        diementions.y || 0,
        diementions.width || video.videoWidth,
        diementions.height || video.videoHeight,
        diementions.x || 0,
        diementions.y || 0,
        diementions.width || video.videoWidth,
        diementions.height || video.videoHeight
      );
    canvas.toBlob(async (blob) => {
      const formData = new FormData();
      formData.append("files", blob);
      const { data } = await axios.post(
        `${API_URL}/screenshot/upload`,
        formData
      );
      if (data.success) {
        const fileId = data.success[0].id;
        await axios.post(`${API_URL}/screenshot/tag`, {
          fileId: fileId,
          videoId: videoId,
          category: category,
        });
        res();
        cb && cb();
      }
    });
  });
  toast.promise(tagImage, {
    success: "Image Tagged",
    loading: "Tagging Image...",
    error: "Image Tagging Failed",
  });
}

const FunctionView = () => {
  const popRef = useRef();
  const navigate = useNavigate();
  const [functions, setFunctions] = useState([]);
  const [loading, setLoading] = useState(true);
  const [diementions, setDiementions] = useState({});
  const [tagValue, setTagValue] = useState();
  const [showNext, setShowNext] = useState(true);
  const { id, name } = useParams();

  const getFunctionIds = async () => {
    const { data } = await axios.get(`${API_URL}/functions`);
    if (data) {
      setFunctions([
        ...data.map((item) => {
          return {
            value: item._id,
            label: item.name,
          };
        }),
      ]);
    }
  };

  const getApps = useCallback(async () => {
    const { data } = await axios.get(`${API_URL}/apps?functionId=${id}`);
    if (data.apps) {
      setApps(data.apps);
    }
    if (data.websites) {
      setWebsites(data.websites);
    }
  }, [id]);

  useEffect(() => {
    getApps();
  }, [id, getApps]);
  useEffect(() => {
    getFunctionIds();
  }, []);

  const [apps, setApps] = useState([]);
  const [app, setApp] = useState({
    value: "",
    label: "",
  });
  const [websites, setWebsites] = useState([]);
  const [website, setWebsite] = useState({
    value: "",
    label: "",
  });
  const [videoLoading, setVideoLoading] = useState(true);
  const [sessions, setSessions] = useState([]);
  const [categories, setCategories] = useState([]);
  const [skip, setSkip] = useState(0);
  const [error, setError] = useState("");
  const getCategories = useCallback(async () => {
    const { data } = await axios.get(`${API_URL}/categories`);
    if (data) {
      setCategories([
        ...data.map((item) => {
          return {
            value: item._id,
            label: item.name,
            description: item.description,
          };
        }),
      ]);
    }
  }, []);

  const getSessions = useCallback(async () => {
    setError("");
    setLoading(true);
    const { data } = await axios.get(
      `${API_URL}/sessions?functionId=${id}&skip=${skip}&limit=1${
        app.value ? `&app_name=${app.value}` : ""
      }${website.value ? `&website_name=${website.value}` : ""}`
    );
    if (data) {
      setSessions(data);
    } else {
      setShowNext(false);
    }
    setLoading(false);
  }, [skip, id, app, website]);
  useEffect(() => {
    getSessions();
  }, [skip, getSessions, id, app, website]);

  useEffect(() => {
    getCategories();
  }, [getCategories]);

  return (
    <div>
      <div className="px-24 my-8 flex items-center justify-between">
        {IconSamespace}
        <div className="flex items-center justify-center gap-x-4">
          {getCookie("verify") === "stBFXFcKYK" && (
            <button
              onClick={() => {
                navigate("/verify");
              }}
              className="px-4 py-2 font-semibold text-sm bg-blue-500 text-white rounded-full shadow-sm"
            >
              Verify
            </button>
          )}
          <Select
            value={{
              label: name,
              value: id,
            }}
            options={functions}
            onChange={(e) => {
              navigate(`/${e.value}/${e.label}`);
              setSkip(0);
            }}
          />

          <div className="relative">
            {app.value && (
              <div
                onClick={() => {
                  setApp({});
                }}
                className="absolute text-[10px] text-blue-500 top-[-15px] right-0 cursor-pointer"
              >
                Clear
              </div>
            )}
            <Select
              className="w-[150px]"
              controlClassName="h-[42px] truncate"
              value={app.value ? app : null}
              placeholder="App"
              options={apps
                .filter((item) => item)
                .map((item) => {
                  return {
                    value: item,
                    label: item,
                  };
                })}
              onChange={(e) => {
                setApp(e);
              }}
            />
          </div>

          <div className="relative">
            {website.value && (
              <div
                onClick={() => {
                  setWebsite({});
                }}
                className="absolute text-[10px] text-blue-500 top-[-15px] right-0 cursor-pointer"
              >
                Clear
              </div>
            )}
            <Select
              className="w-[150px]"
              controlClassName="h-[42px] truncate"
              placeholder="Website"
              value={website.value ? website : null}
              options={websites
                .filter((item) => item)
                .map((item) => {
                  return {
                    value: item,
                    label: item,
                  };
                })}
              onChange={(e) => {
                setWebsite(e);
              }}
            />
          </div>
        </div>
      </div>
      {loading && (
        <div className="h-full w-full flex items-center justify-center loading-container">
          <Loader />
        </div>
      )}
      {!loading && (
        <div className="flex items-center justify-center loading-container">
          {!loading && sessions.length === 0 && (
            <div className="flex flex-col justify-center items-center">
              {IconNoVideos}
              <div className="my-2">No Videos Found</div>
            </div>
          )}
          {sessions.map((session) => {
            return (
              <div key={session.video_id}>
                <VideoLoader
                  videoLoading={videoLoading}
                  setVideoLoading={setVideoLoading}
                  setError={setError}
                  error={error}
                  key={session.video_id}
                  videoId={session.video_id}
                />
                <div className="flex items-center justify-between mt-5">
                  <div>
                    <div>
                      {BROWSERS.includes(session.app_fg)
                        ? session.app_fg_url_host
                        : session.app_fg || "Unknown"}
                    </div>
                    <div className="opacity-50 w-[50ch] truncate">
                      {BROWSERS.includes(session.app_fg)
                        ? session.app_fg_url
                        : session.app_fg_title || "Unknown"}
                    </div>
                  </div>
                  <div className="flex items-center justify-center">
                    <div className="pr-[20px] flex items-center gap-x-[20px]">
                      {skip > 0 && (
                        <button
                          onClick={() => {
                            setSkip(skip - 1);
                            setError("");
                            setShowNext(true);
                          }}
                          className="px-4 py-2 font-semibold text-sm bg-white text-black border rounded-full shadow-sm"
                        >
                          Previous
                        </button>
                      )}
                      {showNext && (
                        <button
                          onClick={() => {
                            setSkip(skip + 1);
                            setError("");
                          }}
                          className="px-4 py-2 font-semibold text-sm bg-white text-black border rounded-full shadow-sm"
                        >
                          Next
                        </button>
                      )}
                    </div>

                    <Popover
                      ref={popRef}
                      trigger
                      preferPlace="above"
                      popup={
                        <div className="w-[300px] p-4">
                          <div className="text-[18px] mb-2">Add Tag</div>
                          <Select
                            components={{
                              Option: OptionLayout,
                            }}
                            menuPlacement="top"
                            value={tagValue}
                            onChange={(e) => {
                              setTagValue(e);
                            }}
                            menuPortalTarget={document.body}
                            options={categories}
                          />
                          <div className="flex items-center justify-end gap-x-4 mt-4">
                            <button
                              onClick={() => {
                                popRef.current.hide();
                              }}
                              className="px-4 py-2 font-semibold text-sm bg-white text-black border rounded-full shadow-sm"
                            >
                              Cancel
                            </button>

                            <button
                              disabled={!tagValue}
                              onClick={() => {
                                videoCapture(
                                  diementions,
                                  tagValue,
                                  sessions[0].video_id,
                                  () => {
                                    getSessions();
                                  }
                                );
                                setTagValue("");
                                popRef.current.hide();
                              }}
                              className="disabled:opacity-50 px-4 py-2 font-semibold text-sm bg-blue-500 text-white border rounded-full shadow-sm"
                            >
                              Save
                            </button>
                          </div>
                        </div>
                      }
                    >
                      <div className="border-l border-gray-300 pl-[20px]">
                        <button
                          disabled={error || videoLoading}
                          onClick={() => {
                            setDiementions(session.dimensions || {});
                            const videoTag =
                              document.querySelector("#function-player");
                            videoTag.pause();
                          }}
                          className="px-4 py-2 font-semibold text-sm bg-blue-500 text-white rounded-full shadow-sm disabled:opacity-50"
                        >
                          Capture
                        </button>
                      </div>
                    </Popover>
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      )}
    </div>
  );
};

export default FunctionView;
