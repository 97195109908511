import { Routes, Route } from "react-router-dom";
import FunctionView from "./FunctionView";

import Home from "./Home";
import Verify from "./Verify";

import { Toaster } from "react-hot-toast";
import { getCookie } from "./utils/common";

const App = () => {
  return (
    <>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/:id/:name" element={<FunctionView />} />
        {getCookie("verify") === "stBFXFcKYK" && (
          <Route path="/verify" element={<Verify />} />
        )}
      </Routes>
      <Toaster position="bottom-right" />
    </>
  );
};

export default App;
