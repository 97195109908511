export const BROWSERS = [
  "Google Chrome",
  "Microsoft Edge",
  "Safari",
  "Opera",
  "Brave",
  "Firefox",
  "Arc",
  "UC Browser",
  "Internet Explorer",
  "Internet Explorer 11",
  "Google Chrome Canary",
];
