import React from "react";
import ReactDOM from "react-dom";
import App from "./App";

import { BrowserRouter } from "react-router-dom";

import "./styles/index.generated.css";

import { getCookie } from "./utils/common";

ReactDOM.render(
  <BrowserRouter>
    {getCookie("secret_token") === "rGwHs6eUO6zSqSfp" ? (
      <App />
    ) : (
      <h1 className="flex h-screen flex-col w-screen items-center justify-center">
        <span className="text-2xl">
          You are not authorized to view this page
        </span>
        <br />
        <input
          placeholder="Enter Passcode"
          className="border"
          onChange={(e) => {
            if (e.target.value === "rGwHs6eUO6zSqSfp") {
              document.cookie = "secret_token=rGwHs6eUO6zSqSfp";
              window.location.reload();
            }
          }}
        />
      </h1>
    )}
  </BrowserRouter>,
  document.getElementById("root")
);
